export default {
  workSchedule: 'Work Schedules',
  agencyWorkSchedule: 'Work Schedule',
  type: 'Type',
  workDate: 'Work date',
  scheduleCalendar: 'Schedule calendar',
  scheduleTag: 'Schedule tag',
  nextSchedule: 'Next Schedule',
  currentSchedule: 'Current Schedule',
  employee: 'Employee',
  INTERNATIONAL: 'International',
  INLAND: 'Inland',
  INLAND_AND_INTERNATIONAL: 'Inland and International',
  BOOK_GROUP: 'Book group',
  create: {
    title: 'Create work schedule',
  },

  edit: {
    title: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
  },

  placeholder: {
    type: 'Select type',
  },

  action: 'Action',
  employeePhoneNumber: 'Employee Phone Number',
  scheduleCalendarTimes: 'Schedule Calendar Times',

  deleteScheduleSuccess: 'Delete Schedule Success',
  createScheduleSuccess: 'Create Schedule Success',
  updateScheduleSuccess: 'Update Schedule Success',
  editScheduleSuccess: 'Edit Schedule Success',
  editScheduleError: 'Edit Schedule Error',

  seeMore: 'See more',
  seeLess: 'See less',
}

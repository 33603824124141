export default {
  paginationText: {
    showing: 'Showing',
    to: 'to',
    from: 'from',
    outOf: 'out of',
    items: 'items',
    total: 'Total',
  },

  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',

  shortSunday: 'Sun',
  shortMonday: 'Mon',
  shortTuesday: 'Tue',
  shortWednesday: 'Wed',
  shortThursday: 'Thu',
  shortFriday: 'Fri',
  shortSaturday: 'Sat',

  'chủ nhật': 'Sunday',
  'thứ hai': 'Monday',
  'thứ ba': 'Tuesday',
  'thứ tư': 'Wednesday',
  'thứ năm': 'Thursday',
  'thứ sáu': 'Friday',
  'thứ bảy': 'Saturday',
  account: 'Account',
  contact: 'Contact',
  contacts: 'Contacts',
  historyBooking: 'History booking',
  filter: 'Filter',
  search: 'Search',
  refresh: 'Refresh',
  createdBy: 'Created by',
  clear: 'Clear',
  delete: 'Delete',
  import: 'Import',
  export: 'Export',
  back: 'Back',
  next: 'Next',
  send: 'Send',
  create: 'Create',
  messBoards: 'Message Board',
  edit: 'Edit',
  update: 'Update',
  adjust: 'Adjust',
  replace: 'Replace',
  duplicate: 'Duplicate',
  cancelEdit: 'Cancel edit',
  save: 'Save',
  saving: 'Saving...',
  cancel: 'Cancel',
  done: 'Approval',
  pendingPayment: 'Pending',
  password: 'Password',
  information: 'Information',
  noInfo: 'No information',
  accountInfoText: 'Account information',
  employeeType: 'Employee type',
  addNew: 'Add New',
  filters: 'Filters',
  confirmation: 'Confirmation',
  placeholderSelect: 'Please select',
  backToHome: 'Back to home',
  moreDropdownTitle: 'More',
  tickets: 'tickets',
  reports: 'Reports',
  userName: 'Username',
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  phoneNumber: 'Phone number',
  backupPhoneNumber: 'Backup phone number',
  dob: 'Date of birth',
  description: 'Description',
  gender: 'Gender',
  placeholderUsername: 'Enter user name',
  placeholderEmail: 'Enter email',
  placeholderFirstName: 'Enter first name',
  placeholderLastName: 'Enter last name',
  placeholderSelectType: 'Select type',
  placeholderPhoneNumber: 'Enter phone number',
  placeholderDoB: 'Choose date of birth',
  placeholderDescription: 'Enter description',
  passengerType: 'Type of Passenger',
  placeholderPassengerType: 'Select type',
  placeholderSelectGender: 'Select gender',

  address: 'Address',
  noResultFund: 'No results found for',
  needSelect_1: 'You need select',
  needSelect_2: 'first.',
  country: 'Country',
  placeholderCountry: 'Select country',
  loadingCountryText: 'Loading country...',
  city: 'Province/City',
  placeholderCity: 'Select city',
  loadingCityText: 'Loading city...',
  district: 'District',
  placeholderDistrict: 'Select District',
  loadingDistrictText: 'Loading District...',
  ward: 'Sub District/Ward',
  placeholderWard: 'Select ward',
  loadingWardText: 'Loading ward...',
  street: 'Address street',
  placeholderStreet: 'Enter street',

  tryAgain: 'Try again',
  importHeader: 'Import excel/CSV',
  importBodyText_1: 'You can import a Excel file to add',
  importBodyText_2: 'into the system.',
  customers: 'Customer(s)',
  employees: 'Employee(s)',
  agencies: 'agency(s)',
  loadFailText_1: 'Loaded',
  loadFailText_2: 'file failed, because:',
  importSuccess_1: 'Click',
  importSuccess_2: 'here',
  importSuccess_3: 'to download the recently imported file.',
  btnAddFileText: 'Add Excel file',
  importLoading: 'Importing...',
  importCompleted: 'Import process is completed.',
  importFailed: 'Import process is failed, because:',
  dropFileText: 'or drop file to upload',
  fileLoading_1: 'Loading',
  fileLoading_2: 'file',
  downloadSampleText_1: 'Click',
  downloadSampleText_2: 'here',
  downloadSampleText_3: 'to download sample file.',
  importValidateHeader_1: 'Loaded',
  importValidateHeader_2: 'file successfully.',
  uploadAgain: 'Upload again',
  formTitle_1: 'Upload file',
  formTitle_2: 'Validate data',
  formTitle_3: 'Done',
  importDoneText: 'items were imported successfully',
  importFailText: 'items failed to import:',
  noRecordFund: 'No matching records found',

  contactInfoText: 'Contact Information',
  placeholderSearch: 'Name, email...',

  previewMail: 'Preview Mail',
  createdMsg_1: 'Your account has been created!',
  createdMsg_2:
    'Your account has been created. Please log in to your account using the following information:',
  createdMsg_3: "Please don't reply to this email.",
  passwordMuted: '(Generated after clicking send)',
  sendTo: 'To:',
  logout: 'Logout',
  pricing: 'Pricing',
  inbox: 'Inbox',
  profile: 'Profile',
  task: 'Task',
  chat: 'Chat',
  applyDate: 'Apply time',
  flightDate: 'Flight time',
  'Pre paid': 'Pre paid',
  'Post paid': 'Post paid',

  upload: 'Upload',
  reset: 'Reset',
  saveChanges: 'Save changes',

  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',

  'Bamboo Airways': 'Bamboo Airways',
  'Vietnam Airlines': 'Vietnam Airlines',
  Vietjet: 'Vietjet Air',

  noMatchingResult: 'No matching records found.',
  pageNotFound: 'Page Not Found',
  notAuthorized: {
    title: 'You are not authorized! 🔐',
    text: 'You don’t have permission to access this page. Go Home!!',
    backToHomeButton: 'Back to Home',
  },
  oops: 'Oops!',
  notFoundRequest: 'The requested URL was not found on this server.',
  english: 'English',
  vietnam: 'Vietnamese',
  language: 'Language',
  Error: 'Error',
  'Add Flights': 'Add Flights',

  Username: 'User',
  Agency: 'Agency',
  overPercentage: 'Over percentage limit',
  featureInDevelopment: 'This feature in development!',
  Reservation: 'Reservation',
  Flights: 'Flights',

  accountsPayableTemp: 'Debts',
  lockBalance: 'Lock balance',
  loading: 'Loading ...',

  bankAccountsInfo: 'Agency\'s bank accounts list',
  reloadAccountsPayable: 'Reload accounts payable',
  from: 'from',
  to: 'to',
  by: 'by',
  close: 'Close',
  agree: 'Agree',
  using: 'Using',
  apply: 'Apply',
  ENABLE: 'Enable',
  DISABLE: 'Disable',

  copy: 'Copy',
  bankSellingRate: 'Rate',
  latestUpdate: 'Latest update',
  Unknown: 'Unknown',
  pleaseCheckError: 'Please check the error code',
  loginSessionExpired: 'Login session expired',
  loginSessionExpiredContent: 'Login session has expired, please log in again!!!',
  loginDataHasBeChanged: 'Login information has been changed',
  loginDataHasBeChangedContent: 'You have logged in/logged out on another tab. Please reload the page!',
  reloadPage: 'Reload page',
  important: 'Important',
  noSuitableOptions: 'There are no suitable options',
  inputMoneyPlaceholder: 'Enter amount...',
  Warning: 'Warning!',
  Notification: 'Notification!',
  show: 'Show',
  hide: 'Hide',
  errorCopy: 'Error copy',
  copied: 'Copied!',
  status: 'Status',
  imgNotFound: 'Image not found',
  page: 'page',
  submit: 'Submit',
  All: 'All',
  empty: 'empty',
  default: 'Default',
  action: 'Actions',
}

export default {
  workSchedule: 'Lịch trực',
  agencyWorkSchedule: 'Lịch trực',
  type: 'Nghiệp vụ',
  workDate: 'Ngày',
  scheduleCalendar: 'Ca trực',
  scheduleTag: 'Thẻ tag',
  nextSchedule: 'Ca tiếp theo',
  currentSchedule: 'Ca hiện tại',
  employee: 'Nhân viên',
  INTERNATIONAL: 'Quốc tế',
  INLAND: 'Nội địa',
  INLAND_AND_INTERNATIONAL: 'NĐ và QT',
  BOOK_GROUP: 'Vé đoàn',
  create: {
    title: 'Tạo lịch trực',
  },

  edit: {
    title: 'Sửa lịch trực',
    cancel: 'Hủy',
    save: 'Lưu',
  },

  placeholder: {
    type: 'Chọn loại nghiệp vụ',
  },

  action: 'Hành động',
  employeePhoneNumber: 'Số điện thoại',
  scheduleCalendarTimes: 'Ca trực',

  deleteScheduleSuccess: 'Xóa lịch trực thành công',
  createScheduleSuccess: 'Tạo lịch trực thành công',
  updateScheduleSuccess: 'Cập nhật lịch trực thành công',
  editScheduleSuccess: 'Sửa lịch trực thành công',
  editScheduleError: 'Sửa lịch trực không thành công',

  seeMore: 'Xem thêm',
  seeLess: 'Thu gọn',
}
